import type { BreadcrumbSegment } from '@local/content-area/dist/Breadcrumbs/BreadcrumbsContext';
import { useBreadcrumbs } from '@local/content-area/dist/Breadcrumbs/BreadcrumbsContext';
import { setDocumentTitle } from '@local/workspaces/dist/utils/setDocumentTitle';
import { useEffect } from 'react';

import { PageContent } from 'src/components/pageContent/PageContent';
import { InstanceUsersList } from 'src/pages/instanceUsersPage/InstanceUsersList';
import { instanceUsersBreadcrumb } from 'src/utils/breadcrumbs';

import { INSTANCE_USERS_TITLE } from '../../strings';

export function InstanceUsersPage() {
    setDocumentTitle('Users');

    const { setBreadcrumbs } = useBreadcrumbs();
    const segments: BreadcrumbSegment[] = [{ name: instanceUsersBreadcrumb.name }];
    useEffect(() => {
        setBreadcrumbs(segments);
    }, []);

    return (
        <PageContent pageTitle={INSTANCE_USERS_TITLE} enablePageTitle={false}>
            <InstanceUsersList />
        </PageContent>
    );
}
