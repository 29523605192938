import { getOrgUuidFromParams } from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import type { Params } from 'react-router';

export const WORKSPACES_BREADCRUMB = 'Workspaces';
export const WORKSPACE_FILES_BREADCRUMB = 'Files';
export const ADMINISTRATORS_BREADCRUMB = 'Administrators';
export const INSTANCE_USER_MANAGEMENT_BREADCRUMB = 'Users';
export const DISCOVERY_BREADCRUMB = 'Discover';
export const SEARCH_BREADCRUMB = 'Search';
export const RECYCLE_BIN_BREADCRUMB = 'Recycle bin';
export const WEBVIZ_VIEWER_BREADCRUMB = 'Viewer';

export const usersBreadcrumb = { name: ADMINISTRATORS_BREADCRUMB, path: '/administrators' };
export const instanceUsersBreadcrumb = {
    name: INSTANCE_USER_MANAGEMENT_BREADCRUMB,
    path: '/users',
};
export const discoveryBreadcrumb = { name: DISCOVERY_BREADCRUMB, path: '/discover' };
export const searchBreadcrumb = { name: SEARCH_BREADCRUMB, path: '/search' };
export const recycleBinBreadcrumb = { name: RECYCLE_BIN_BREADCRUMB, path: '/recyclebin' };

export const workspacesPath = (params: Params) => {
    const orgUuid = getOrgUuidFromParams(params);
    return `/${orgUuid}/workspaces`;
};

export const workspaceListingWithLinkBreadcrumb = (params: Params) => [
    {
        name: WORKSPACES_BREADCRUMB,
        path: workspacesPath(params),
    },
];
