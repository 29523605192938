import { trackError } from '@local/metrics';
import Copy from '@local/web-design-system-2/dist/icons/Copy';
import Delete from '@local/web-design-system-2/dist/icons/Delete';
import Download from '@local/web-design-system-2/dist/icons/Download';
import Overflow from '@local/web-design-system-2/dist/icons/Overflow';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { hasRoleOrHigher } from '@local/workspaces/dist/utils/permissions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useParams } from 'react-router';

import { useLazyGetFileByIdQuery } from 'src/apiClients/enhancedFileMiddleware';
import {
    type FileVersionResponse,
    type ListFile,
} from 'src/apiClients/GENERATED_fileClientEndpoints';
import { useSnackBarContext } from 'src/contexts/SnackBarContext';
import { useWorkspaceContext } from 'src/contexts/WorkspaceContext';
import { useRecycleItemHandler } from 'src/hooks/useRecycleItemHandler';
import { COPIED_TO_CLIPBOARD, COPY, RECYCLE, DOWNLOAD, DOWNLOAD_FILE_FAILED } from 'src/strings';
import { ItemType } from 'src/types/ItemTypes';

import { useStyles } from './FileActions.styles';

interface ActionProps {
    iconOnly?: boolean;
    onClick?: () => void;
}

function DownloadAction({ iconOnly, onClick }: ActionProps) {
    const { classes } = useStyles();

    if (iconOnly && onClick) {
        return (
            <Button
                onClick={onClick}
                automation-id="download-button"
                variant="outlined"
                color="secondary"
                fullWidth
                startIcon={<Download />}
            >
                <Typography variant="body1">{DOWNLOAD}</Typography>
            </Button>
        );
    }

    return (
        <Grid container justifyContent="flex-start" alignItems="center" spacing={0.5}>
            <Grid item>
                <Download viewBox="0 0 24 20" />
            </Grid>
            <Grid item>
                <Typography className={classes.actionText}>{DOWNLOAD}</Typography>
            </Grid>
        </Grid>
    );
}

function DeleteAction({ iconOnly, onClick }: ActionProps) {
    const { classes } = useStyles();
    const { workspaceUserRole } = useWorkspaceContext();

    const disabled = workspaceUserRole ? !hasRoleOrHigher(workspaceUserRole, 'editor') : false;

    if (iconOnly && onClick) {
        return (
            <Button
                onClick={onClick}
                automation-id="delete-button"
                variant="outlined"
                color="secondary"
                fullWidth
                startIcon={<Delete />}
                disabled={disabled}
            >
                <Typography variant="body1">{RECYCLE}</Typography>
            </Button>
        );
    }

    return (
        <Grid container justifyContent="flex-start" alignItems="center" spacing={0.5}>
            <Grid item>
                <Delete viewBox="0 0 24 20" />
            </Grid>
            <Grid item>
                <Typography className={classes.actionText}>{RECYCLE}</Typography>
            </Grid>
        </Grid>
    );
}

function CopyAction() {
    const { classes } = useStyles();

    return (
        <Grid container justifyContent="flex-start" alignItems="center" spacing={0.5}>
            <Grid item>
                <Copy viewBox="0 0 24 20" />
            </Grid>
            <Grid item>
                <Typography className={classes.actionText}>{COPY}</Typography>
            </Grid>
        </Grid>
    );
}

interface Props {
    file: Pick<ListFile, 'file_id' | 'name' | 'version_id'>;
    /** If provided, will be used for context when issuing download or delete queries */
    version?: FileVersionResponse;
    /** Whether to display icons, otherwise will display a dropdown menu of options */
    icons?: boolean;
}

export function FileActions({ file, version, icons }: Props) {
    const { classes } = useStyles();
    const { setSnackBar } = useSnackBarContext();
    const params = useParams();
    const { workspaceUserRole } = useWorkspaceContext();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [fetchFile, { isLoading: isDownloading }] = useLazyGetFileByIdQuery();

    const isDisabled = workspaceUserRole ? !hasRoleOrHigher(workspaceUserRole, 'editor') : false;

    const { ConfirmRecycleDialog, setRecycleDialogOpen, isRecycleItemHandlerLoading } =
        useRecycleItemHandler();

    const handleDownload = async () => {
        handleClose();
        const { data, isError, error } = await fetchFile({
            organisationId: getOrgUuidFromParams(params),
            workspaceId: getSelectedWorkspaceFromParams(params),
            fileId: file.file_id,
            versionId: version?.version_id,
        });

        if (isError) {
            trackError('Error downloading file', JSON.stringify(error));
            setSnackBar(DOWNLOAD_FILE_FAILED, 'error', null);
            return;
        }

        if (!data) {
            return;
        }

        // When the download button is clicked, we fetch the file by ID from the API, which gives us the
        // download link. This code is to automatically trigger that download link, instead of presenting the
        // user with another download button to click again.
        const downloadLink = document.createElement('a');
        downloadLink.href = data.download;
        downloadLink.download = file.name;
        downloadLink.click();
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(version!.version_id);
        setSnackBar(COPIED_TO_CLIPBOARD, 'success', null);
        handleClose();
    };

    const openDeleteDialog = () => {
        handleClose();
        setRecycleDialogOpen(true);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (isRecycleItemHandlerLoading || isDownloading) {
        if (icons) {
            return (
                <div className={classes.icons}>
                    <Skeleton variant="circular" width={38} height={38} />
                </div>
            );
        }
        return <Skeleton variant="circular" width={38} height={38} />;
    }

    if (icons) {
        // Render an inline list of icon buttons only
        return (
            <Stack direction="row" spacing={2}>
                <DownloadAction onClick={handleDownload} iconOnly />
                {!version && <DeleteAction onClick={() => setRecycleDialogOpen(true)} iconOnly />}
                {version && <CopyAction />}
                <ConfirmRecycleDialog
                    items={[{ id: file.file_id, name: file.name }]}
                    itemType={ItemType.FILE}
                />
            </Stack>
        );
    }

    // Render a menu with dropdown options
    return (
        <>
            <Grid container alignItems="center" justifyContent="center" item xs>
                <IconButton size="large" onClick={handleClick} automation-id="overflow-icon">
                    <Overflow fontSize="small" />
                </IconButton>
            </Grid>
            <Menu
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={handleDownload} automation-id="menu-menu-item-download-file">
                    <DownloadAction />
                </MenuItem>
                {!version && (
                    <div
                        aria-hidden
                        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                            event.stopPropagation();
                        }}
                    >
                        <MenuItem
                            disabled={isDisabled}
                            onClick={openDeleteDialog}
                            automation-id="menu-menu-item-delete-file"
                        >
                            <DeleteAction />
                        </MenuItem>
                    </div>
                )}
                {version && (
                    <MenuItem
                        onClick={handleCopy}
                        disabled={isDisabled}
                        automation-id="menu-menu-item-copy-file-id"
                    >
                        <CopyAction />
                    </MenuItem>
                )}
            </Menu>
            <ConfirmRecycleDialog
                items={[{ id: file.file_id, name: file.name }]}
                itemType={ItemType.FILE}
            />
        </>
    );
}
