/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import type { ListFile } from 'src/apiClients/GENERATED_fileClientEndpoints';

import type { RootState } from '../store';

export interface MultiSelectState {
    checked: { id: string; name: string }[];
}

const initialState: MultiSelectState = {
    checked: [],
};

export const multiSelectSlice = createSlice({
    name: 'multiSelect',
    initialState,
    reducers: {
        toggleChecked: (
            state: MultiSelectState,
            action: PayloadAction<{ id: string; name: string }>,
        ) => {
            const index = state.checked.findIndex((item) => item.id === action.payload.id);
            if (index === -1) {
                state.checked.push(action.payload);
            } else {
                state.checked.splice(index, 1);
            }
        },
        checkAll: (
            state: MultiSelectState,
            action: PayloadAction<{ id: string; name: string }[]>,
        ) => {
            state.checked = action.payload;
        },
    },
});

const multiSelectState = (state: RootState) => state.multiSelect;

export const checkedSelection: any = createSelector(
    [multiSelectState],
    (state: MultiSelectState) => state.checked,
);

export const isItemChecked = (id: string) =>
    createSelector([checkedSelection], (checked: { id: string; name: string }[]) =>
        checked.some((item) => item.id === id),
    );

export const fileCheckedSelector = createSelector(
    [(state: RootState) => state, (_: RootState, files: ListFile[]) => files],
    (state, files) =>
        files.reduce(
            (accumulator, file) => {
                accumulator[file.file_id] = isItemChecked(file.file_id)(state);
                return accumulator;
            },
            {} as Record<string, boolean>,
        ),
);

export const { toggleChecked, checkAll } = multiSelectSlice.actions;
