import type { BreadcrumbSegment } from '@local/content-area/dist/Breadcrumbs/BreadcrumbsContext';
import { useBreadcrumbs } from '@local/content-area/dist/Breadcrumbs/BreadcrumbsContext';
import { useSidebarContext } from '@local/content-area/dist/Sidebar/SidebarContext';
import { checkIsAdmin } from '@local/login';
import { fetchWorkSpaceData } from '@local/workspaces/dist/apiClients/workspaceClientEndpoints';
import { getSelectedWorkspaceFromParams } from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { setDocumentTitle } from '@local/workspaces/dist/utils/setDocumentTitle';
import Grid from '@mui/material/Grid';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { useParams } from 'react-router';

import { PageContent } from 'src/components/pageContent/PageContent';
import { usePersistedState } from 'src/hooks/usePersistedState';
import { AffinityFoldersContent } from 'src/pages/workspacePage/affinityFoldersContent/AffintyFoldersContent';
import { workspaceContentModeSelector } from 'src/store/features/displaySettingSlice';
import { useAppSelector } from 'src/store/store';
import { workspaceListingWithLinkBreadcrumb } from 'src/utils/breadcrumbs';

import { Sidebar } from './workspaceContent/sidebar/Sidebar';
import { WorkspaceContent } from './workspaceContent/WorkspaceContent';
import { NotFound } from './WorkspaceNotFound';

export const WorkspacePage = ({ tabs }: { tabs: JSX.Element }) => {
    const params = useParams();
    const [id] = usePersistedState('id');
    const [folderID] = usePersistedState('folderID');
    const { setSidebarContent } = useSidebarContext();

    const fileFolderUUID = id ?? folderID;

    const { evouiAffinityFolders } = useFlags();
    const workspaceContentMode = useAppSelector(workspaceContentModeSelector);
    const isAdmin = checkIsAdmin();

    const { data, isLoading, isError } = fetchWorkSpaceData(params, isAdmin);
    const workspaceId = getSelectedWorkspaceFromParams(params);

    const { setBreadcrumbs } = useBreadcrumbs();
    const segments: BreadcrumbSegment[] = [
        ...workspaceListingWithLinkBreadcrumb(params),
        { name: data?.name ?? '' },
    ];

    useEffect(() => {
        setBreadcrumbs(segments);
    }, [isLoading]);

    useEffect(() => {
        setSidebarContent(
            <Sidebar
                fileFolderUUID={fileFolderUUID}
                isFile={Boolean(id)}
                workspaceId={workspaceId}
            />,
        );

        return () => {
            // Clear sidebar when leaving the page
            setSidebarContent(undefined);
        };
    }, [fileFolderUUID, workspaceId]);

    if (isError || (!isLoading && !data)) {
        return <NotFound />;
    }

    if (!isLoading) {
        setDocumentTitle(data?.name ?? '');
    }

    return (
        <Grid container wrap="nowrap" direction="column" sx={{ height: '100%' }}>
            <PageContent
                pageTitle={data?.name ?? ''}
                pageTitleLoading={isLoading}
                titleActions={tabs}
            >
                {!isLoading &&
                    (evouiAffinityFolders && workspaceContentMode === 'affinity-folders' ? (
                        <AffinityFoldersContent />
                    ) : (
                        <WorkspaceContent />
                    ))}
            </PageContent>
        </Grid>
    );
};
