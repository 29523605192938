import type { OrgListedObject } from '@api/goose/dist/enhancedGooseClient';
import { extractObjectSchemaName } from '@api/goose/dist/utils/extractObjectSchemaName';
import ListItem from '@local/web-design-system-2/dist/components/GenericListing/ListItem';
import TableSkeleton from '@local/web-design-system-2/dist/components/TableSkeleton/TableSkeleton';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';

import { usePersistedState } from 'src/hooks/usePersistedState';
import { useRecycleItemHandler } from 'src/hooks/useRecycleItemHandler';
import { isItemChecked, toggleChecked } from 'src/store/features/multiSelectSlice';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { BLOCKSYNC_DELETE_DISABLED_MESSAGE, BLOCKSYNC_SCHEMA } from 'src/strings';
import { ItemType } from 'src/types/ItemTypes';

import { objectDefinition } from './FieldRowDefinitions';
import { ObjectActions } from './ObjectActions';
import type { ListedObjectDisplay } from './utils';

interface ObjectRowProps {
    object: ListedObjectDisplay | OrgListedObject;
    displayEmptyActions?: boolean;
    enableCheckbox?: boolean;
}

export function ObjectRow({
    object,
    displayEmptyActions = false,
    enableCheckbox = false,
}: ObjectRowProps) {
    const [id, setID] = usePersistedState('id');
    const { ConfirmRecycleDialog, setRecycleDialogOpen, isRecycleItemHandlerLoading } =
        useRecycleItemHandler();
    const isSelected = id === object?.object_id;

    const handleRowClick = () => {
        if (isSelected) {
            setID('');
        } else {
            setID(object?.object_id);
        }
    };

    const dispatch = useAppDispatch();

    const itemChecked = useAppSelector(isItemChecked(object.object_id));
    const handleCheck = (event: React.MouseEvent) => {
        event.stopPropagation();
        dispatch(toggleChecked({ id: object.object_id, name: object.name }));
    };

    if (isRecycleItemHandlerLoading) {
        return <TableSkeleton columns={6} rows={1} hasAction />;
    }

    return (
        <>
            <ListItem
                key={`${object.object_id}-${itemChecked}`}
                item={object}
                automation-id="row"
                selected={isSelected}
                clickHandler={handleRowClick}
                fields={objectDefinition}
                checkbox={
                    enableCheckbox && (
                        <Tooltip
                            title={
                                extractObjectSchemaName(object.schema) === BLOCKSYNC_SCHEMA
                                    ? BLOCKSYNC_DELETE_DISABLED_MESSAGE
                                    : null
                            }
                            onClick={(event) => event.stopPropagation()}
                        >
                            <span>
                                <Checkbox
                                    color="primary"
                                    onClick={handleCheck}
                                    checked={itemChecked}
                                    disabled={
                                        extractObjectSchemaName(object.schema) === BLOCKSYNC_SCHEMA
                                    }
                                />
                            </span>
                        </Tooltip>
                    )
                }
                displayEmptyActions={displayEmptyActions}
                actions={
                    <ObjectActions object={object} onRecycle={() => setRecycleDialogOpen(true)} />
                }
            />
            <ConfirmRecycleDialog
                items={[{ id: object.object_id, name: object.name }]}
                itemType={ItemType.OBJECT}
            />
        </>
    );
}
