import {
    useDeleteObjectsByIdMutation,
    useEnhancedUpdateObjectByIdMutation,
} from '@api/goose/dist/enhancedGooseClient';
import { useMessagesContext } from '@local/messages-wds2/dist/MessagesContext';
import { trackError } from '@local/metrics';
import OverflowTooltip from '@local/web-design-system-2/dist/components/OverflowTooltip/OverflowTooltip';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { hasRoleOrHigher } from '@local/workspaces/dist/utils/permissions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import {
    useDeleteFileByIdMutation,
    useUpdateFileByIdMutation,
} from 'src/apiClients/enhancedFileMiddleware';
import { useSearchParamsContext } from 'src/contexts/SearchParamsContext';
import { useSnackBarContext } from 'src/contexts/SnackBarContext';
import { useWorkspaceContext } from 'src/contexts/WorkspaceContext';
import { checkAll, toggleChecked } from 'src/store/features/multiSelectSlice';
import { useAppDispatch } from 'src/store/store';
import {
    MULTI_RECYCLE_OBJECT_DIALOG_TITLE,
    MULTI_RECYCLE_DIALOG_CONTENT,
    MULTI_RECYCLE_DIALOG_WARNING,
    MULTI_RECYCLE_DIALOG_CANCEL,
    MULTI_RECYCLE_DIALOG_CONFIRM,
    MULTI_RECYCLE_OBJECT_TOAST_SUCCESS,
    MULTI_RESTORE_OBJECT_TOAST_SUCCESS,
    UNDO,
    MULTI_VIEW_OBJECTS,
    RECYCLE_DIALOG_CANCEL,
    RECYCLE_DIALOG_CONFIRM,
    RECYCLE_DIALOG_CONTENT,
    RECYCLE_DIALOG_TITLE,
    RECYCLE_DIALOG_WARNING,
    RECYCLE_TOAST_SUCCESS,
    RESTORE_OBJECT_TOAST_SUCCESS,
    VIEW_RECYCLED_OBJECTS,
    MULTI_RECYCLE_FILE_TOAST_SUCCESS,
    MULTI_RECYCLE_FILES_DIALOG_TITLE,
    MULTI_RESTORE_FILE_TOAST_SUCCESS,
    MULTI_VIEW_FILES,
    RECYCLE_FILE_CANCEL,
    RECYCLE_FILE_CONFIRM,
    RECYCLE_FILE_DIALOG_CONTENT,
    RECYCLE_FILE_DIALOG_TITLE,
    RECYCLE_FILE_DIALOG_WARNING,
    RECYCLE_FILE_SUCCESS,
    RESTORE_FILE_TOAST_SUCCESS,
    VIEW_RECYCLED_FILE,
    TRY_AGAIN,
    FAILED_TO_RECYCLE,
    FAILED_TO_RESTORE,
} from 'src/strings';
import { ItemType } from 'src/types/ItemTypes';
import { formatObjectName } from 'src/utils/objectUtils';

import { usePersistedState } from './usePersistedState';

interface SnackbarSuccessActionsProps {
    items: { id: string; name: string }[];
    itemType: ItemType;
    messages: {
        recycleSuccess: string;
        recycleFailure: string;
        restoreSuccess: string;
        restoreFailure: string;
        undo: string;
        viewRecycledItems: string;
        tryAgain: string;
    };
}

interface SnackbarFailureActionsProps {
    items: { id: string; name: string }[];
    itemType: ItemType;
    messages: {
        recycleSuccess: string;
        recycleFailure: string;
        restoreSuccess: string;
        restoreFailure: string;
        undo: string;
        viewRecycledItems: string;
        tryAgain: string;
    };
    recycling: boolean;
}
interface OnRecycleProps {
    confirm: boolean | null;
    items: { id: string; name: string }[];
    itemType: ItemType;
    messages: {
        recycleSuccess: string;
        recycleFailure: string;
        restoreSuccess: string;
        restoreFailure: string;
        undo: string;
        viewRecycledItems: string;
        tryAgain: string;
    };
}

interface OnRestoreProps {
    items: { id: string; name: string }[];
    itemType: ItemType;
    messages: {
        recycleSuccess: string;
        recycleFailure: string;
        restoreSuccess: string;
        restoreFailure: string;
        undo: string;
        viewRecycledItems: string;
        tryAgain: string;
    };
}

interface ConfirmRecycleDialogProps {
    items: { id: string; name: string }[];
    itemType: ItemType;
}

export const useRecycleItemHandler = () => {
    const [recycleDialogOpen, setRecycleDialogOpen] = useState(false);
    const params = useParams();
    const { setSnackBar, handleClose } = useSnackBarContext();
    const [searchParams, setSearchParams] = useSearchParamsContext();

    const [recycleObject] = useDeleteObjectsByIdMutation();
    const [recycleFile] = useDeleteFileByIdMutation();

    const [, setID] = usePersistedState('id');

    const dispatch = useAppDispatch();

    const orgId = getOrgUuidFromParams(params);
    const workspaceId = getSelectedWorkspaceFromParams(params);
    const { workspaceUserRole } = useWorkspaceContext();
    const isDisabled = !hasRoleOrHigher(workspaceUserRole ?? null, 'editor');

    const { addMessage } = useMessagesContext();

    const [restoreObject] = useEnhancedUpdateObjectByIdMutation();
    const [restoreFile] = useUpdateFileByIdMutation();

    const [isLoading, setIsLoading] = useState(false);

    const handleRestoreItem = async ({ items, itemType, messages }: OnRestoreProps) => {
        const failedItems: { id: string; name: string }[] = [];
        setIsLoading(true);

        try {
            const promisesWithIds = items.map((item) => {
                switch (itemType) {
                    case ItemType.OBJECT:
                        return {
                            id: item.id,
                            name: item.name,
                            promise: restoreObject({
                                orgId,
                                workspaceId,
                                objectId: item.id,
                                deleted: false,
                                geoscienceObject: null,
                            }).unwrap(),
                        };
                    case ItemType.FILE:
                        return {
                            id: item.id,
                            name: item.name,
                            promise: restoreFile({
                                organisationId: orgId,
                                workspaceId,
                                fileId: item.id,
                                deleted: false,
                            }).unwrap(),
                        };
                    default:
                        return {
                            id: '',
                            name: '',
                            promise: Promise.resolve(),
                        };
                }
            });

            const results = await Promise.allSettled(promisesWithIds.map(({ promise }) => promise));

            results.forEach((result, index) => {
                if (result.status === 'rejected') {
                    failedItems.push({
                        id: promisesWithIds[index].id,
                        name: promisesWithIds[index].name,
                    });
                }
            });

            if (failedItems.length > 0) {
                throw new Error('Error recycling items');
            }

            addMessage({
                message: messages.restoreSuccess,
                severity: 'success',
            });

            handleClose();
        } catch (error) {
            trackError(`Error restoring ${itemType.toLowerCase()}`, JSON.stringify(error));
            setSnackBar(
                `${messages.restoreFailure} ${failedItems.length} ${itemType.toLowerCase()}${
                    failedItems.length > 1 ? 's' : ''
                }`,
                'error',
                <SnackbarFailureActions
                    items={failedItems}
                    itemType={itemType}
                    messages={messages}
                    recycling={false}
                />,
            );
        }

        setIsLoading(false);
    };

    function SnackbarSuccessActions({ items, itemType, messages }: SnackbarSuccessActionsProps) {
        const navigate = useNavigate();

        return (
            <>
                <Button
                    color="secondary"
                    size="small"
                    onClick={() => handleRestoreItem({ items, itemType, messages })}
                    sx={{ textDecoration: 'underline' }}
                    automation-id="notification-undo"
                >
                    {messages.undo}
                </Button>
                <Button
                    color="secondary"
                    size="small"
                    onClick={() =>
                        navigate('../recyclebin', {
                            state: {
                                activeTab: itemType === ItemType.OBJECT ? 'objects' : 'files',
                            },
                        })
                    }
                    sx={{ whiteSpace: 'nowrap', textDecoration: 'underline' }}
                    automation-id={`notification-view-${itemType.toLowerCase()}`}
                >
                    {messages.viewRecycledItems}
                </Button>
            </>
        );
    }

    function SnackbarFailureActions({
        items,
        itemType,
        messages,
        recycling,
    }: SnackbarFailureActionsProps) {
        return (
            <Button
                color="secondary"
                size="small"
                onClick={() =>
                    recycling
                        ? handleRecycle({ confirm: true, items, itemType, messages })
                        : handleRestoreItem({ items, itemType, messages })
                }
                sx={{ whiteSpace: 'nowrap', textDecoration: 'underline' }}
            >
                {messages.tryAgain}
            </Button>
        );
    }

    const handleRecycle = async ({ confirm, items, itemType, messages }: OnRecycleProps) => {
        setRecycleDialogOpen(false);

        if (!confirm || !items || isDisabled) {
            return;
        }

        setIsLoading(true);
        setID(undefined);
        dispatch(checkAll([]));

        const failedItems: { id: string; name: string }[] = [];

        try {
            const promisesWithIds = items.map((item) => {
                switch (itemType) {
                    case ItemType.OBJECT:
                        return {
                            id: item.id,
                            name: item.name,
                            promise: recycleObject({
                                objectId: item.id,
                                orgId,
                                workspaceId,
                            }).unwrap(),
                        };
                    case ItemType.FILE:
                        return {
                            id: item.id,
                            name: item.name,
                            promise: recycleFile({
                                organisationId: orgId,
                                workspaceId,
                                fileId: item.id,
                            }).unwrap(),
                        };
                    default:
                        return {
                            id: '',
                            name: '',
                            promise: Promise.resolve(),
                        };
                }
            });

            const results = await Promise.allSettled(promisesWithIds.map(({ promise }) => promise));

            results.forEach((result, index) => {
                if (result.status === 'rejected') {
                    const { id, name } = promisesWithIds[index];
                    failedItems.push({ id, name });
                    dispatch(toggleChecked({ id, name }));
                }
            });

            if (failedItems.length > 0) {
                throw new Error('Error recycling items');
            }

            setSnackBar(
                messages.recycleSuccess,
                'success',
                <SnackbarSuccessActions items={items} itemType={itemType} messages={messages} />,
            );

            searchParams.delete('id');
            setSearchParams(searchParams);
        } catch (error) {
            trackError(`Error deleting ${itemType.toLowerCase()}`, JSON.stringify(error));
            setSnackBar(
                `${messages.recycleFailure} ${failedItems.length} ${itemType.toLowerCase()}${
                    failedItems.length > 1 ? 's' : ''
                }`,
                'error',
                <SnackbarFailureActions
                    items={failedItems}
                    itemType={itemType}
                    messages={messages}
                    recycling
                />,
            );
        }

        setIsLoading(false);
    };

    const getMessages = (itemType: ItemType, amount: 'single' | 'multi') => {
        const messageDictionary = {
            object: {
                single: {
                    dialogTitle: RECYCLE_DIALOG_TITLE,
                    dialogContent: RECYCLE_DIALOG_CONTENT,
                    dialogWarning: RECYCLE_DIALOG_WARNING,
                    dialogCancel: RECYCLE_DIALOG_CANCEL,
                    dialogConfirm: RECYCLE_DIALOG_CONFIRM,
                    recycleSuccess: RECYCLE_TOAST_SUCCESS,
                    recycleFailure: FAILED_TO_RECYCLE,
                    restoreSuccess: RESTORE_OBJECT_TOAST_SUCCESS,
                    restoreFailure: FAILED_TO_RESTORE,
                    undo: UNDO,
                    viewRecycledItems: VIEW_RECYCLED_OBJECTS,
                    tryAgain: TRY_AGAIN,
                },
                multi: {
                    dialogTitle: MULTI_RECYCLE_OBJECT_DIALOG_TITLE,
                    dialogContent: MULTI_RECYCLE_DIALOG_CONTENT,
                    dialogWarning: MULTI_RECYCLE_DIALOG_WARNING,
                    dialogCancel: MULTI_RECYCLE_DIALOG_CANCEL,
                    dialogConfirm: MULTI_RECYCLE_DIALOG_CONFIRM,
                    recycleSuccess: MULTI_RECYCLE_OBJECT_TOAST_SUCCESS,
                    recycleFailure: FAILED_TO_RECYCLE,
                    restoreSuccess: MULTI_RESTORE_OBJECT_TOAST_SUCCESS,
                    restoreFailure: FAILED_TO_RESTORE,
                    undo: UNDO,
                    viewRecycledItems: MULTI_VIEW_OBJECTS,
                    tryAgain: TRY_AGAIN,
                },
            },
            file: {
                single: {
                    dialogTitle: RECYCLE_FILE_DIALOG_TITLE,
                    dialogContent: RECYCLE_FILE_DIALOG_CONTENT,
                    dialogWarning: RECYCLE_FILE_DIALOG_WARNING,
                    dialogCancel: RECYCLE_FILE_CANCEL,
                    dialogConfirm: RECYCLE_FILE_CONFIRM,
                    recycleSuccess: RECYCLE_FILE_SUCCESS,
                    recycleFailure: FAILED_TO_RECYCLE,
                    restoreSuccess: RESTORE_FILE_TOAST_SUCCESS,
                    restoreFailure: FAILED_TO_RESTORE,
                    undo: UNDO,
                    viewRecycledItems: VIEW_RECYCLED_FILE,
                    tryAgain: TRY_AGAIN,
                },
                multi: {
                    dialogTitle: MULTI_RECYCLE_FILES_DIALOG_TITLE,
                    dialogContent: MULTI_RECYCLE_DIALOG_CONTENT,
                    dialogWarning: MULTI_RECYCLE_DIALOG_WARNING,
                    dialogCancel: MULTI_RECYCLE_DIALOG_CANCEL,
                    dialogConfirm: MULTI_RECYCLE_DIALOG_CONFIRM,
                    recycleSuccess: MULTI_RECYCLE_FILE_TOAST_SUCCESS,
                    recycleFailure: FAILED_TO_RECYCLE,
                    restoreSuccess: MULTI_RESTORE_FILE_TOAST_SUCCESS,
                    restoreFailure: FAILED_TO_RESTORE,
                    undo: UNDO,
                    viewRecycledItems: MULTI_VIEW_FILES,
                    tryAgain: TRY_AGAIN,
                },
            },
        };

        return messageDictionary[itemType][amount];
    };

    function ConfirmRecycleDialog({ items, itemType }: ConfirmRecycleDialogProps) {
        if (!items || items.length === 0) {
            return null;
        }

        const messages = getMessages(itemType, items.length > 1 ? 'multi' : 'single');
        let recycleString = `${messages.dialogContent}`;
        let recycleTitle = '';

        if (items.length > 1) {
            recycleString += ` ${items.length} ${itemType.toLowerCase()}s?`;
        } else if (itemType === ItemType.OBJECT) {
            recycleString += ` "${formatObjectName(items[0].name)}"?`;
            recycleTitle = `"${formatObjectName(items[0].name)}"?`;
        } else if (itemType === ItemType.FILE) {
            recycleString += ` "${items[0].name}"?`;
            recycleTitle = `"${items[0].name}"?`;
        }

        return (
            <Dialog
                open={recycleDialogOpen}
                onClose={() => handleRecycle({ confirm: false, items, itemType, messages })}
                sx={{ maxWidth: '444px', margin: 'auto' }}
            >
                <DialogTitle automation-id="dialog-title">{messages.dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText automation-id="dialog-description">
                        <OverflowTooltip title={recycleTitle} maxLines={2}>
                            {recycleString}
                        </OverflowTooltip>
                        {messages.dialogWarning}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ padding: 2 }}>
                    <Button
                        autoFocus
                        color="secondary"
                        variant="outlined"
                        onClick={() =>
                            handleRecycle({
                                confirm: false,
                                items,
                                itemType,
                                messages,
                            })
                        }
                        automation-id="dialog-cancel-button"
                    >
                        {messages.dialogCancel}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => handleRecycle({ confirm: true, items, itemType, messages })}
                        automation-id="dialog-confirm-button"
                    >
                        {messages.dialogConfirm}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return { ConfirmRecycleDialog, setRecycleDialogOpen, isRecycleItemHandlerLoading: isLoading };
};
