import { ErrorScreen } from '@local/svgs/dist/pageState';
import { NotFoundSvg } from '@local/svgs/dist/svg/NotFoundSvg';
import EmptyState from '@local/web-design-system-2/dist/components/EmptyState/EmptyState';
import TableSkeleton from '@local/web-design-system-2/dist/components/TableSkeleton/TableSkeleton';
import {
    getHubUrlForCurrentOrg,
    getOrgUuidFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { NETWORK_ERROR_DESCR } from '@local/workspaces/dist/strings';
import type {
    BaseInstanceUserResponse,
    BaseInstanceUserRoleResponse,
} from '@local/workspaces/src/apiClients/GENERATED_workspaceClientEndpoints';
import { useListInstanceUsersQuery } from '@local/workspaces/src/apiClients/GENERATED_workspaceClientEndpoints';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router';

import {
    INSTANCE_USERS_EMPTY_MESSAGE,
    INSTANCE_USERS_EMPTY_TITLE,
    INSTANCE_USERS_HEADER_EMAIL,
    INSTANCE_USERS_HEADER_NAME,
    INSTANCE_USERS_HEADER_ROLE,
    NETWORK_ERROR_TITLE,
} from 'src/strings';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS } from 'src/utils/pagination';

export const InstanceUsersList = () => {
    const params = useParams();
    const instanceId = getOrgUuidFromParams(params);
    const hubUrl = getHubUrlForCurrentOrg();
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

    // Fetch current page data
    const {
        data: userListData,
        isFetching: userListIsLoading,
        isError,
    } = useListInstanceUsersQuery({
        hubUrl,
        orgId: instanceId,
        limit: pageSize,
        offset: page * pageSize,
    });

    // Pre-fetch next page data to determine if list hasNext as bentley RBAC backend can't be trusted in some cases.
    const {
        data: nextPageData,
        isFetching: nextPageIsLoading,
        isError: isNextPageError,
    } = useListInstanceUsersQuery({
        hubUrl,
        orgId: instanceId,
        limit: pageSize,
        offset: (page + 1) * pageSize,
    });

    const instanceUsers = useMemo(() => {
        if (!userListData) {
            return [];
        }
        return userListData.results;
    }, [userListData]);

    const hasNext = useMemo(() => {
        if (!nextPageData || nextPageIsLoading || isNextPageError || !userListData?.links.next) {
            return false;
        }

        // Check if next page data has results and current page data has the same number of results as page size we can be sure there is a next page.
        if (nextPageData.results.length > 0 && userListData.results.length === pageSize) {
            return true;
        }
        return false;
    }, [nextPageData, nextPageIsLoading, isNextPageError, userListData]);

    const emptyResults = !userListIsLoading && instanceUsers?.length === 0;
    const hasPrevious = userListData?.links?.previous !== null && !userListIsLoading;

    const getRolesAsCsv = (roles: BaseInstanceUserRoleResponse[]): string =>
        roles.map((role) => role.name).join(', ');

    const handleChangePage = (event: React.MouseEvent | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newPageSize = parseInt(event.target.value, 10);
        setPageSize(newPageSize);
        setPage(0);
    };

    if (isError) {
        return <ErrorScreen msg={NETWORK_ERROR_TITLE} details={NETWORK_ERROR_DESCR} />;
    }

    return (
        <Grid sx={{ overflowY: 'auto', paddingTop: '0px', width: '100%' }}>
            <TablePagination
                automation-id="users-list-table-pagination"
                page={page}
                disabled={!instanceUsers.length}
                component="div"
                rowsPerPage={pageSize}
                labelRowsPerPage="Show"
                count={
                    !hasNext && (!nextPageIsLoading || !userListData?.links.next)
                        ? page * pageSize + instanceUsers.length
                        : -1
                }
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={DEFAULT_PAGE_SIZE_OPTIONS}
                sx={{
                    fontSize: '0.75rem',
                    '.MuiTablePagination-displayedRows': {
                        fontSize: '0.75rem',
                    },
                    '.MuiTablePagination-toolbar': {
                        paddingLeft: '16px',
                    },
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
                slotProps={{
                    actions: {
                        nextButton: { disabled: !hasNext },
                        previousButton: { disabled: !hasPrevious },
                    },
                }}
            />
            {emptyResults ? (
                <EmptyState
                    title={INSTANCE_USERS_EMPTY_TITLE}
                    message={INSTANCE_USERS_EMPTY_MESSAGE}
                    image={<NotFoundSvg />}
                    sx={{
                        margin: 'auto',
                        width: '50%',
                    }}
                />
            ) : (
                <TableContainer sx={{ maxHeight: 'calc(100vh - 280px)' }}>
                    <Table stickyHeader size="small" automation-id="instance-user-table">
                        <TableHead data-dd-privacy="allow">
                            <TableRow>
                                <TableCell>{INSTANCE_USERS_HEADER_NAME}</TableCell>
                                <TableCell>{INSTANCE_USERS_HEADER_EMAIL}</TableCell>
                                <TableCell>{INSTANCE_USERS_HEADER_ROLE}</TableCell>
                                <TableCell width="100px" />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {userListIsLoading ? (
                                <TableSkeleton rows={5} columns={3} />
                            ) : (
                                instanceUsers.map((user: BaseInstanceUserResponse) => (
                                    <TableRow
                                        key={user.id}
                                        sx={{ height: '62px' }}
                                        automation-id={`user-row-${user.id}`}
                                    >
                                        <TableCell>{user.full_name}</TableCell>
                                        <TableCell automation-id="row-value-email">
                                            {user.email}
                                        </TableCell>
                                        <TableCell automation-id="row-value-role">
                                            {getRolesAsCsv(user.roles)}
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Grid>
    );
};
