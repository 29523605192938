import { Close } from '@local/web-design-system-2';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import type { ReactNode } from 'react';

import { checkAll } from 'src/store/features/multiSelectSlice';
import type { useAppDispatch } from 'src/store/store';
import { TOOLBAR_SELECTED } from 'src/strings';

interface CheckedItemsToolbarProps {
    dispatch: ReturnType<typeof useAppDispatch>; // React doesn't like initialising this inside the component
    numChecked: number;
    actions: { key: string; action: ReactNode }[];
}
export function CheckedItemsToolbar(props: CheckedItemsToolbarProps): ReactNode {
    const { dispatch, numChecked, actions } = props;
    const handleClose = () => {
        dispatch(checkAll([]));
    };
    return (
        <Stack>
            {numChecked > 0 && (
                <Toolbar
                    sx={[
                        {
                            bgcolor: (theme) =>
                                alpha(
                                    theme.palette.secondary.light,
                                    theme.palette.action.activatedOpacity,
                                ),
                        },
                        {
                            gap: 1,
                            marginY: 1,
                        },
                    ]}
                    variant="dense"
                    automation-id="checked-items-toolbar"
                >
                    <Tooltip title="Close" key="close">
                        <IconButton
                            onClick={handleClose}
                            color="inherit"
                            automation-id="toolbar-close-button"
                        >
                            <Close />
                        </IconButton>
                    </Tooltip>
                    <Typography color="inherit" variant="subtitle1" component="div">
                        {numChecked} {TOOLBAR_SELECTED}
                    </Typography>
                    {actions.map(({ key, action }) => (
                        <Stack key={key}>{action}</Stack>
                    ))}
                </Toolbar>
            )}
        </Stack>
    );
}
